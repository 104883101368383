      import "./App.css";

function App() {

  return (
    <div className="App">
      <div className="logo-text-container">
          <img
            src="/src/assets/emb.svg"
            className="logo"
            alt="vide"
          />
          <h1 className="title">Lyricscope</h1>
      </div>
      <div className="body-content">
      <h1>Your guide to the world of lyrics and sounds.</h1>
      <span>Unlock the deeper meanings of soungs with out AI-powered analysis, revealing insights into every lyeic.</span>
      <input type="text" class="search-input" placeholder="search"/>
      </div>
      
    </div>
  );
}

export default App;
